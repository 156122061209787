<template>
  <b-overlay :show="show" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          รายการสมาชิกเล่นล่าสุด
        </div>

        <div class="mb-25">
          ระบบจะแสดงสมาชิกที่เข้าเล่น ย้อนหลัง {{ activeChart }} วัน ตั้งแต่วันที่
          {{ dateStart }} ถึง {{ dateEnd }}
        </div>

        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                เวลาที่ล็อคอินล่าสุด
              </div>
            </div>

            <flat-pickr v-model="lastLogin" class="form-control" placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }" @input="ChangeDate()" />
          </div>
          <button class="btn btn-primary ml-1" @click="GetList()">
            ค้นหา
          </button>
        </div>

        <div class="row no-gutters mt-2">
          <div class="col-auto col mr-2" style="padding: 0px">
            <div class="row no-gutters text-primary">
              ระยะเวลาทีี่เข้าเล่น
            </div>
            <div class="row no-gutters">
              <!-- <div class="d-flex"> -->
              <button class="mr-50 btn" :class="[
                activeChart === 3
                  ? 'btn-warning'
                  : 'btn-outline-warning',
              ]" @click="activeChart = 3, ChangeDate()">
                3 วัน
              </button>

              <button class="btn" :class="[
                activeChart === 7
                  ? 'btn-warning'
                  : 'btn-outline-warning',
              ]" @click="activeChart = 7, ChangeDate()">
                7 วัน
              </button>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-12 col-sm-auto col-xl-6 mx-0 mx-md-12 col" style="padding: 0px">
            <div class="row no-gutters text-primary">
              ยอดเทิร์นโอเวอร์
            </div>
            <div class="row no-gutters">
              <input id="input-low" v-model="min_turn" class="form-control" placeholder="ต่ำสุด" style="width: 180px"
                type="text">
              <span class="d-flex align-items-center" style="padding: 0.5rem">ถึง</span>
              <input id="input-high" v-model="max_turn" class="form-control" placeholder="สูงสุด" style="width: 180px"
                type="text">
            </div>
          </div>
        </div>

        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div class="row no-gutters align-items-center">
            <div class="col-6">
              <h4 class="mb-0">
                ผลลัพธ์ {{ items.length }}
              </h4>
            </div>
          </div>
        </div>
      </b-card-body>

      <b-table small striped hover responsive show-empty class="position-relative items-center"
        :current-page="currentPage" :per-page="perPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px">
            {{
              scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
            }}
          </div>
        </template>

        <template #cell(last_dep_amount)="data">
          {{ Commas(data.item.last_dep_amount) }}
        </template>
        <template #cell(last_wit_amount)="data">
          {{ Commas(data.item.last_wit_amount) }}
        </template>
        <template #cell(turnover)="data">
          {{ Commas(data.item.turnover) }}
        </template>
        <template #cell(last_play)="data">
          {{ CalDateAgo(data.item.last_play) }}
        </template>
        <template #cell(last_slot_play)="data">
          {{ CalDateAgo(data.item.last_slot_play) }}
        </template>
        <template #cell(last_sport_play)="data">
          {{ CalDateAgo(data.item.last_sport_play) }}
        </template>
        <template #cell(last_casino_play)="data">
          {{ CalDateAgo(data.item.last_casino_play) }}
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="GetList()" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BCardBody,
  BOverlay,
  BIconController,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardBody,
    BOverlay,
    BIconController,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      itemModal: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeChart: 3,
      fields: [
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'tel', label: 'เบอร์' },
        { key: 'name', label: 'ชื่อ' },
        { key: 'last_dep_at', label: 'วันที่ฝากล่าสุด', sortable: true },
        { key: 'last_dep_amount', label: 'ยอดฝาก', sortable: true, formatter: val => val.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) },
        {
          key: 'total_deposit', label: 'ยอดฝากรวม', sortable: true, formatter: val => val.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        },
        { key: 'last_wit_at', label: 'วันที่ถอนล่าสุด', sortable: true },
        { key: 'last_wit_amount', label: 'ยอดถอน', sortable: true },
        { key: 'turnover', label: 'ยอดเทิร์นโอเวอร์', sortable: true },
        { key: 'last_play', label: 'วันที่เล่นในระบบล่าสุด', sortable: true },
        { key: 'last_slot_play', label: 'วันที่เล่นสล็อตล่าสุด', thClass: 'text-center', sortable: true },
        { key: 'last_sport_play', label: 'วันที่เล่นกีฬาล่าสุด', thClass: 'text-center', sortable: true },
        { key: 'last_casino_play', label: 'วันที่เล่นคาสิโนล่าสุด', thClass: 'text-center', sortable: true },
        // {
        //   key: 'created_at',
        //   label: 'สมัครเมื่อ',
        //   formatter: value => (value
        //     ? moment(value)
        //       .tz('Asia/Bangkok')
        //       .add('hours', 7)
        //       .format('DD-MM-YYYY HH:mm')
        //     : ''),
        // },
      ],
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').subtract(3, 'days').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      lastLogin: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      min_turn: null,
      max_turn: null,
      show: false,
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // this.totalRows = this.items.length
    this.GetList()
  },
  methods: {
    ChangeDate() {
      this.dateStart = moment(this.lastLogin).tz('Asia/Bangkok').subtract(this.activeChart, 'days').format('YYYY-MM-DD')
      this.dateEnd = moment(this.lastLogin).tz('Asia/Bangkok').format('YYYY-MM-DD')
    },
    async GetList() {
      const obj = {
        start_date: this.dateStart,
        end_date: this.dateEnd,
        min_turn: this.min_turn,
        max_turn: this.max_turn,
      }
      this.show = true
      try {
        const { data } = await this.$http.post('/history/member/play', obj)
        // console.log(data)
        this.totalRows = data.data.length
        this.items = data.data
        this.show = false
      } catch (e) {
        console.log(e)
        this.show = false
      }
    },
    CalDateAgo(date) {
      if (date && date !== '-') {
        const startDate = moment(date)
        const endDate = moment(this.lastLogin)
        const daysDiff = endDate.diff(startDate, 'days')
        return `${date} (${daysDiff} วันที่แล้ว)`
      }
      return '-'
    },
    Commas(x) {
      if (!x || x === '-') {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, 0.42) !important;
}

.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
